import React from 'react';
import Layout from '../components/layout/layout';
import activite1Img from '../images/activities/activite1.png';
import activite2Img from '../images/activities/activite2.png';
import activite3Img from '../images/activities/activite3.png';
import activite4Img from '../images/activities/poterie.png';
import activite5Img from '../images/activities/reunion.png';
import activite6Img from '../images/activities/teinture.png';
import avecLesFemmes from '../images/activities/a-mererue-avec-les-femmes.jpg';
import emergence from '../images/activities/mererue-emergence.jpg';
import noel from '../images/activities/noel-mererue.jpg';
import senegalMonPays from '../images/activities/senegal-mon-pays.jpg';
import covid19 from '../images/activities/covid19-mererue.jpg';
import weekendHumanitaire from '../images/activities/weekend-humanitaire.jpg';
import circoncision from '../images/activities/circoncision-mererue.jpg';
import { Container } from '../components/container';
import { Heading1, Heading2 } from '../components/headings';
import { Text } from '../components/text';
import { Section } from '../components/section';
import { BasicCard } from '../components/card';
import { LinkToVideo } from '../components/link-to-video';

const ActivitiesPage = () => (
  <Layout title="Nos Activités">
    <Section>
      <Container maxWidth="4xl" className="px-4 sm:px-8 text-center space-y-4">
        <Heading1>Nos Activités</Heading1>
        <Text as="p" size="lg">
          La Maison d’Encadrement et de Réinsertion des enfants de la Rue comprend deux programmes: un pour les enfants et un pour les
          familles.
        </Text>
      </Container>
    </Section>
    <Section className="text-lg">
      <Container maxWidth="4xl" className="space-y-8">
        <section className="space-y-4">
          <Heading2>Nos dernières vidéos </Heading2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <LinkToVideo to="https://youtu.be/erDrWlfOWrU" imageSrc={circoncision} title="Circoncision à Mererue 2021" />
            <LinkToVideo to="https://youtu.be/thtq_6NbFAo" imageSrc={weekendHumanitaire} title="Weekend humanitaire AFDEAA-MERERUE" />
            <LinkToVideo to="https://youtu.be/e1oWqOEnluk" imageSrc={covid19} title="COVID19 : Gestes barrières et Appui Mererue" />
            <LinkToVideo to="https://youtu.be/wJ2329ftOvM" imageSrc={avecLesFemmes} title="A MERERUE avec les femmes" />
            <LinkToVideo to="https://youtu.be/M5RToHvZ8_I" imageSrc={emergence} title="MERERUE en voie d'émergence" />
            <LinkToVideo to="https://youtu.be/VHLtFLUUyww" imageSrc={noel} title="Noël à MERERUE" />
            <LinkToVideo to="https://youtu.be/iXWEyE_q5aI" imageSrc={senegalMonPays} title="Sénégal mon pays"/>
          </div>
        </section>

        <section className="space-y-4">
          <Heading2>Programme pour les enfants</Heading2>
          <BasicCard className="space-y-6">
            <p>MERERUE adopte le programme du curriculum pour l’insertion des enfants dans les écoles du Sénégal.</p>
            <p>
              Un programme cognitif et ludique est dispensé par un personnel éducatif compétent, et bénévole. Des cours d’enseignement
              général et religieux y sont dispensés en plus du programme d’hygiène sanitaire et nutritionnel.
            </p>
            <p>
              Alphabétisation (lecture, écriture, calcul), Éducation à la paix et à la citoyenneté, Hygiène sanitaire et nutritionnelle. Art
              et musique, Informatique, Éducation religieuse, Formation professionnelle des enfants les plus grands. Des cours d’arabe et
              d’éducation physique et sportive y sont dispensés.
            </p>
            <figure className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <img src={activite1Img} alt="Famille" />
              <img src={activite2Img} alt="Famille" />
              <img src={activite3Img} alt="Famille" />
            </figure>
          </BasicCard>
        </section>
        <section className="space-y-4">
          <Heading2>Programme pour les familles</Heading2>
          <BasicCard className="space-y-6">
            <p>
              Les familles bénéficient d’une alphabétisation en langue nationale wolof et de formation en activités génératrices de revenus
              (tapisserie, macramé, couture, transformation de fruits et légumes, de produits halieutiques, micro jardinage…). Elles sont
              sensibilisées sur des thématiques telle que l’importance de l’éducation de base, la santé, l’alimentation, la protection de
              l’environnement, la bonne gestion des ressources...
            </p>
            <p>
              Le staff de MERERUE a en outre organisé des séances de dépistage du VIH/SIDA en partenariat avec le service régional de
              Développement Communautaire et contribué à la construction du poste de santé de SOR DAGA et des campagnes d’inscription à
              l’ETAT CIVIL avec l’appui du Président du tribunal régional de Saint-Louis.
            </p>
            <figure className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <img src={activite4Img} alt="Famille" />
              <img src={activite5Img} alt="Famille" />
              <img src={activite6Img} alt="Famille" />
            </figure>
          </BasicCard>
        </section>
        <section className="space-y-4">
          <Heading2>Encadrement</Heading2>
          <BasicCard className="space-y-6">
            <p>
              MERERUE est sous la supervision de sa directrice honoraire,{' '}
              <a href="https://youtu.be/TArfyNUJYH4" className="text-green-500 underline">
                Mme Amina Sow MBAYE
              </a>
              , Grand-Croix de l’Ordre National du Lion.
            </p>
            <p>Un personnel permanent composé d’éducateurs bénévoles qui se relaient assure le programme.</p>
            <p>
              Les éducateurs ont bénéficié de divers stages en la matière sous la direction de Mme Ndeye Issa WADE, Présidente Régionale de
              l'AFDEAA, Directrice de MERERUE. Les monitrices suivent à longueur d’année des cours de perfectionnement et ont obtenu un
              diplôme en partenariat avec l’Institut Culturel Français (ICF).
            </p>
            <p>
              Dans le programme du MEN (Apprendre sans peur), Mme Ndeye Issa Wade est nommée Secrétaire Communale à la sensibilisation de «
              Enseigner sans violence », elle est également championne du Club des enfants de Plan Saint-Louis.
            </p>
            <p>Mme Magatte Thioye Ndiaye est relais santé communautaire par Plan Saint Louis.</p>
            <p>
              MERERUE est organisation membre du projet PDC Africa, winner AEIF 2012 dans le cadre du projet Femmes et Jeunes d’Afrique Unis
              pour la Paix, la démocratie et la Citoyenneté subventionné par le département d’Etat des USA.
            </p>
          </BasicCard>
        </section>
      </Container>
    </Section>
  </Layout>
);

export default ActivitiesPage;
